import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/Layout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Experience`}</h1>
    <h2>{`University of Technology Sydney, Broadway`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Research Assistance (2020 - Current)`}</p>
    </blockquote>
    <p>{`Following on from my brain-computer interface (BCI) capstone experience, I have started assisting with multiple neuroscience applications for PhD students and researchers at UTS. Some of these projects included AR-guided navigation and improvements in high-performance mobile BCI interfaces.`}</p>
    <p>{`Working on these projects exposed me to several application frameworks for Android, the Microsoft Hololens and The Robot Operating System. This work revealed a passion and focus for my future, namely leveraging technologies such as these to produce novel applications that ultimately benefit society. Ultimately professional and academic development in this field led to my PhD application in BCI research at UTS to continue work with these teams.`}</p>
    <h2>{`Dataforce Services, Bella Vista`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Applications Engineer (2017 - Current)`}</p>
    </blockquote>
    <p>{`Dataforce Services is a data management organisation responsible for several applications that facilitate government-regulated projects, particularly those in the energy efficiency sector.`}</p>
    <p>{`Within this role, I was initially exposed to software documentation and pushed out of my comfort zone in full-stack web development utilising technologies such as PHP, jQuery & PL/SQL. Through this experience, I gained a thorough understanding of the full software development lifecycle.`}</p>
    <p>{`Today, I am independently responsible for several mobile applications running on iOS and Android with over 5000 users. I have experienced both maintenance and green fields development for native and cross-platform development. Through this development, I have also leveraged several emerging technologies such as Kotlin's multiplatform programming.`}</p>
    <h2>{`ASI Solutions, Botany`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Project Technician (2015 - 2016)`}</p>
    </blockquote>
    <p>{`Over a fixed contract I was independently tasked by ASI Solutions to deliver a large scale PC upgrade for the NSW Central Coast Local Health District.`}</p>
    <p>{`In this role I gained familiarity with the management of sensitive user data, enterprise-grade software such as SCCM and independent project management.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      